<template>
  <div class="bg-black-0 rounded p-3 shadow-sm">
    <h2 v-if="mode === 'add'">Tambah Data Barang Keluar (Blood Bag)</h2>
    <h2 v-else>Edit Data Barang Keluar (Blood Bag)</h2>
    <b-form class="mt-5" @submit.prevent="onSubmit" @reset="onReset">
      <b-form-group
        id="input-group-3"
        label="Kode Barang :"
        label-for="input-3"
      >
        <v-select
          v-model="formData.item_code"
          :options="tipeSelected"
          label="item_name"
          :reduce="(item) => item.item_code"
          :get-option-label="
            (option) => `${option.item_code} - ${option.item_name}`
          "
          placeholder="Pilih Kode Barang"
          aria-placeholder="Pilih Kode Barang"
        >
          <template #option="{ item_code, item_name }">
            <span>{{ item_code }} - {{ item_name }}</span>
          </template>
        </v-select>
      </b-form-group>
      <b-form-group label="Barcode:" label-for="barcodeInput">
        <b-form-input
          id="barcodeInput"
          v-model="formData.barcode"
          type="text"
          placeholder="Masukkan Barcode"
        />
      </b-form-group>
      <b-form-group label="Nilai Koagulan:" label-for="coagulantInput">
        <b-form-input
          id="coagulantInput"
          v-model="formData.coagulant"
          type="text"
          placeholder="Masukkan Nilai Koagulan"
        />
      </b-form-group>
      <b-form-group label="Nilai LOT:" label-for="lotInput">
        <b-form-input
          id="lotInput"
          v-model="formData.lot"
          placeholder="Masukkan Nilai LOT"
        />
      </b-form-group>
      <b-form-group label="Tanggal Kadaluarsa:" label-for="expDateInput">
        <b-form-input
          id="expDateInput"
          v-model="formData.exp_date"
          type="date"
          placeholder="dd-mm-yyyy"
        />
      </b-form-group>
      <b-form-group label="Catatan Tertentu:" label-for="noteInput">
        <b-form-textarea
          id="noteInput"
          v-model="formData.note"
          rows="5"
          placeholder="Catatan Tertentu"
        />
      </b-form-group>
      <div class="d-flex justify-content-end">
        <base-button
          text="Reset"
          :handle-click="onReset"
          variant="danger"
          submit-type="reset"
          class="mb-8 mr-8"
        />
        <base-button
          text="Simpan"
          :is-busy="isBusy"
          :loading="isBusy"
          :disabled="isBusy || !isFormValid"
          submit-type="submit"
          variant="primary-1"
          class="mb-8 mr-8"
        />
      </div>
    </b-form>
  </div>
</template>

<script>
import vSelect from "vue-select";
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BToast,
} from "bootstrap-vue";
import manageInventoryAPI from "../../../../../api/manageinventory/manageInventoryAPI";
import outgoingGoodsBloodBagAPI from "../../../../../api/outgoinggoodsbloodbag/outgoingGoodsBloodBagAPI";

export default {
  components: {
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    vSelect,
    BToast,
  },

  data() {
    return {
      mode: "",
      isBusy: false,
      formSubmitted: false,
      formData: {
        item_code: "",
        barcode: "",
        coagulant: "",
        lot: "",
        exp_date: "",
        note: "",
      },
      tipeSelected: [],
    };
  },

  computed: {
    isFormValid() {
      return (
        this.formData.item_code &&
        this.formData.barcode &&
        this.formData.coagulant &&
        this.formData.lot &&
        this.formData.exp_date
      );
    },
  },

  methods: {
    onReset() {
      Object.keys(this.formData).forEach((key) => {
        this.formData[key] = "";
      });
    },

    async getDetails(id) {
      const { data: response } = await outgoingGoodsBloodBagAPI.getDetail(id);
      Object.assign(this.formData, response.data);
    },

    async getAllKodeBarang() {
      const { data: response } = await manageInventoryAPI.getAll();
      this.tipeSelected = response.data.data;
    },
    async onSubmit() {
      this.formSubmitted = true;

      if (!this.isFormValid) {
        this.showErrorToast("Harap lengkapi semua kolom");
        return;
      }

      this.isBusy = true;
      try {
        const expDate = new Date(this.formData.exp_date);
        const today = new Date();
        if (expDate < today) {
          this.showErrorToast(
            "Tanggal kadaluarsa tidak boleh sebelum hari ini"
          );
          return;
        }

        let response;
        if (this.mode === "add") {
          response = await outgoingGoodsBloodBagAPI.add(this.formData);
        } else {
          response = await outgoingGoodsBloodBagAPI.update({
            id: this.$route.params.id,
            ...this.formData,
          });
        }
        if (response.data.success) {
          await this.showToast(response.data);
          setTimeout(() => {
            this.$router.push({ path: "/dashboards/list-outgoing-bloodBag" });
          }, 2000);
        } else {
          this.showErrorToast("Operasi gagal");
        }
      } catch (error) {
        console.error("API Error:", error);
        this.showErrorToast(
          error.response?.data?.message || "Terjadi kesalahan"
        );
      } finally {
        this.isBusy = false;
      }
    },

    showToast(responseData) {
      return new Promise((resolve) => {
        const toasts = [];

        responseData.data.forEach((item) => {
          toasts.push({
            message: `${item.barcode}: ${item.message}`,
            title: item.status === "success" ? "Sukses" : "Gagal",
            variant: item.status === "success" ? "success" : "danger",
          });
        });

        const summaryMessage = `Berhasil: ${responseData.message.insert_successful}, Gagal: ${responseData.message.insert_failed}. ${responseData.message.update_stock}`;
        toasts.push({
          message: summaryMessage,
          title: "Ringkasan",
          variant: "Secondary",
        });

        const showNextToast = (index) => {
          if (index < toasts.length) {
            const toast = toasts[index];
            this.$bvToast.toast(toast.message, {
              title: toast.title,
              variant: toast.variant,
              solid: true,
              autoHideDelay: 5000,
              appendToast: true,
            });
            setTimeout(() => showNextToast(index + 1), 1000);
          } else {
            resolve();
          }
        };

        showNextToast(0);
      });
    },

    showErrorToast(message) {
      this.$bvToast.toast(message, {
        title: "Error",
        variant: "danger",
        solid: true,
        autoHideDelay: 5000,
        appendToast: true,
      });
    },
    async getAllKodeBarang() {
      const { data: response } = await manageInventoryAPI.getAll();
      this.tipeSelected = response.data.data;
    },
    async getDetails(id) {
      const { data: response } = await outgoingGoodsBloodBagAPI.getDetail(id);
      Object.assign(this.formData, response.data);
    },
    intialMode() {
      if (this.$route.params.id) {
        this.mode = "edit";
      } else {
        this.mode = "add";
      }
    },
  },

  mounted() {
    this.intialMode();
    this.getAllKodeBarang();
  },
};
</script>
